<template>
  <header class="header">
    <div class="header__w">
      <Logo
        :orange="hoverLogo"
        @mouseenter="hoverLogo = true"
        @mouseleave="hoverLogo = false"
      />
      <a
        class="header__contact-link"
        :href="`tel:${_T('@Call center number')}`"
      >
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            class="header__contact-img"
            d="M18.1181 14.702L13.9998 15.5C11.2181 14.1038 9.49985 12.5 8.49985 10L9.2698 5.8699L7.81436 2L4.06344 2C2.9359 2 2.04799 2.93178 2.21639 4.04668C2.63679 6.83 3.87638 11.8765 7.49985 15.5C11.305 19.3052 16.7856 20.9564 19.8019 21.6127C20.9666 21.8662 21.9998 20.9575 21.9998 19.7655L21.9998 16.1812L18.1181 14.702Z"
            stroke="white"
            stroke-width="1.5"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
        {{ _T("@Call center number") }}
      </a>
    </div>
  </header>
</template>

<script setup>
import Logo from "~/modules/header/components/UI/Logo.vue";

const hoverLogo = ref(false);
</script>

<style lang="scss" scoped>
.header {
  width: 100%;
  @include flex-container(row, center, center);

  background-color: #393d38;

  padding: 0 16px;

  &__w {
    @include flex-container(row, space-between, center);

    @extend %width-main;
  }

  &__contact-link {
    @include flex-container(row, center, center);
    gap: 16px;

    @include font;
    letter-spacing: 0.02em;
    color: white;

    transition: 0.2s ease;

    @include mobile {
      @include font(14, 18);
    }

    &:hover {
      color: var(--color-primary-base);

      .header__contact-img {
        stroke: var(--color-primary-base);
      }
    }
  }

  &__contact-img {
    transition: stroke 0.2s ease;
  }
}
</style>
